/* eslint-disable no-console */
'use server';
import _ from 'lodash';
import { cookies, headers } from 'next/headers';
import 'server-only';
import getForwardedFor from './getForwardedFor';
import getToken from './getToken';
import setHeader from '@/shared/utils/setHeader';
import toJson from '@/shared/utils/toJson';

type Options<Variables> = {
  variables?: Variables;
  isPrivate?: boolean;
};

const fetchQuery = async <Query, Variables>(
  query: string,
  queryName: string,
  options?: Options<Variables>,
) => {
  try {
    const isAnonymous = queryName.includes('AnonymousQuery');
    const start = Date.now();
    const { variables } = options ?? {};
    const token = isAnonymous ? undefined : getToken();
    const forwardedFor = getForwardedFor();
    const requestId = headers().get('x-request-id');
    const testScenarios = headers().get('x-test-scenarios');
    const testFlagOverwrites = headers().get('x-test-flag-overwrites');
    const uniqueDeviceId = cookies().get('uniqueDeviceId')?.value;
    const xUserAgent =
      headers().get('x-user-agent') || headers().get('user-agent') || '';
    const response = await fetch(getUrl(false), {
      method: 'POST',
      body: JSON.stringify({ query, variables }),
      redirect: 'follow',
      headers: {
        ...setHeader('x-forwarded-for', forwardedFor),
        ...setHeader('x-request-id', requestId),
        ...setHeader('x-test-scenarios', testScenarios),
        ...setHeader('x-test-flag-overwrites', testFlagOverwrites),
        ...setHeader('x-unique-device-id', uniqueDeviceId),
        ...setHeader('x-user-agent', xUserAgent),
        ...setHeader('User-Agent', process.env.WHITELIST_USER_AGENT),
        ...setHeader('Authorization', token, () => `Bearer ${token}`),
        'Content-Type': 'application/json',
      },
    });
    console.log({
      appRouter: true,
      queryName,
      variables: JSON.stringify(variables),
    });

    // if (process.env.LOG_GRAPHQL_REQUESTS === 'true') {
    //   console.log(
    //     queryName,
    //     JSON.stringify({
    //       isAnonymous,
    //       uniqueDeviceId,
    //       requestId,
    //       forwardedFor,
    //       url: getUrl(false),
    //       countryCode: getCountryCode(),
    //       status: response.status,
    //       time: `${Date.now() - start}mls`,
    //     }),
    //   );
    // }
    if (!response.ok) {
      const errorText = await response.text();
      console.error(
        JSON.stringify({
          queryName,
          variables: _.omit(variables as object, ['password']),
          variablesAsString: JSON.stringify(
            _.omit(variables as object, ['password']),
          ),
          status: response.status,
          body: errorText,
          message: `Query response error: [${queryName}]`,
          time: `${Date.now() - start}mls`,
        }),
      );
      return { data: undefined };
    }
    return toJson<Query>(response);
  } catch (error) {
    console.error('graphql request error:', queryName, error);
    return { data: {} } as { data: Query };
  }
};

const getUrl = (isPrivate?: boolean) => {
  if (isPrivate) {
    return process.env.GRAPHQL_PRIVATE_SERVER_URL ?? '';
  }
  return process.env.GRAPHQL_SERVER_URL ?? '';
};

export default fetchQuery;
